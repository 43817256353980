
import { Injectable } from '@angular/core';
import { Job } from '@core/job/job.types';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { QartNotification } from '@core/notification/qart-notification.types';
import { Order } from '@core/order/order.types';
import { CurrencyProxyPipe } from '@core/utils/currency-proxy.pipe';
import { TranslocoService } from '@jsverse/transloco';
import { BehaviorSubject, Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { notificationTemplate } from './notification.data';
import { environment } from '@env/environment';

/**
 * Service for managing notifications.
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  /**
   * The current merchant.
   */
  private _merchant: Merchant;

  /**
   * The list of notifications.
   */
  private _notifications: BehaviorSubject<QartNotification[] | null> = new BehaviorSubject([]);

  /**
   * The translation key for the notification service.
   */
  private _translocoRead: string = 'core.notification';

  /**
   * The socket for receiving notifications.
   */
  private _socket: Socket;

  /**
   * Constructs a new instance of the NotificationService.
   * @param _translocoService The TranslocoService for translations.
   * @param _merchantService The MerchantService for getting the current merchant.
   * @param _currencyPipe The CurrencyProxyPipe for formatting currency.
   */
  constructor(
    private _translocoService: TranslocoService,
    private _merchantService: MerchantService,
    private _currencyPipe: CurrencyProxyPipe,
  ) {
    // Get the merchant
    this._merchantService.merchant$
      .subscribe((merchant: Merchant) => {
        const reload = this._merchant?._id.toString() !== merchant?._id.toString();
        this._merchant = merchant;
        if (reload) {
          this._notifications.next([]);
          console.log('WEBSOCKET: join after receiving new merchant');
          this._join();
        }
      });

    this._socket = io(environment.qart.websocketUrl, {
      secure: false,
      transports: ['websocket'],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax : 5000,
      reconnectionAttempts: 5,
      withCredentials: true
    });

    this._socket.on('connect', () => {
      this._join();
    });
    
    this._socket.on("connect_error", () => {
      console.error('Websocket connection error:', this._socket.id);
    });

    this._socket.on('initial notifications', (notifications: QartNotification[]) => {
      this._notifications.next(notifications.map(notification => this._processNotification(notification)));
    });

    this._socket.on('new notification', (notification: QartNotification) => {
      let notifications = this._notifications.getValue();
      notifications = [this._processNotification(notification, true), ...notifications];
      this._notifications.next(notifications);
    });
    
    this._socket.on('test', (message: string) => {
      console.log("Test notification: ", message);
    });

    this._socket.connect();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns an observable of the current notifications.
   * @returns An observable of QartNotification array.
   */
  get notifications$(): Observable<QartNotification[]> {
    return this._notifications.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Joins the socket connection with the merchant ID.
   * 
   * @returns void
   */
  private _join(): void {
    if ( !this._merchant || !this._socket ) {
      return;
    }
    this._socket.emit('join', this._merchant._id);
  }

  /**
   * Returns the username of a given user object.
   * If the user object has a `name` property, it will return the first name and last name initials.
   * If the user object has an `email` property, it will return the email address before the '@' symbol.
   * If the user object is undefined or null, it will return '[unknown user]'.
   * @param user The user object to get the username from.
   * @returns The username of the given user object.
   */
  private _getUserName(user): string {
    let username: string = '';
    if (user?.name) {
      const names: string[] = user.name.split(' ');
      if (names.length > 1) {
        const initials = names
          .splice(1, names.length - 1)
          .map((name: string) => name.toUpperCase().charAt(0) + '.')
          .join(' ');
        username = `${names[0]} ${initials}`;
      } else {
        username = names[0];
      }
    } else if (user && user.email) {
      username = user.email.split('@')[0];
    } else {
      username = '[unknown user]';
    }
    return username;
  }

  /**
   * Processes a notification and returns a modified QartNotification object.
   * @param notification The notification to be processed.
   * @param incomingNotification A boolean indicating whether the notification is incoming or not. Default is false.
   * @returns A modified QartNotification object.
   */
  private _processNotification(notification: QartNotification, incomingNotification: boolean= false): QartNotification {
    notification.incoming = incomingNotification;
    const display = notificationTemplate.find(e => e.type === notification.type);
    if (display) {
      if (typeof notification.object === 'string' && notification.object) {
        notification.object = JSON.parse(notification.object);
      }
      notification.icon = display.icon;
      const userName = this._getUserName(notification.user);
      if (display.type === 'addCategory') {
        notification.message = this._translocoService.translate(`${this._translocoRead}.add-category`, {
          user: userName,
          category: notification.object?.name
        });
      } else if (display.type === 'deleteCategory') {
        notification.message = this._translocoService.translate(`${this._translocoRead}.delete-category`, {
          user: userName,
          category: notification.object?.name
        });
      } else if (display.type === 'addProduct') {
        notification.message = this._translocoService.translate(`${this._translocoRead}.add-product`, {
          user: userName
        });
      } else if (display.type === 'removeProduct') {
        notification.message = this._translocoService.translate(`${this._translocoRead}.delete-product`, {
          user: userName,
          product: notification.object?.name
        });
      } else if (display.type === 'addEmployee') {
        notification.message = this._translocoService.translate(`${this._translocoRead}.add-employee`, {
          user: userName,
          employee: notification.object?.name
        });
      } else if (display.type === 'updateMerchant') {
        notification.message = this._translocoService.translate(`${this._translocoRead}.update-merchant`, {
          user: userName
        });
      } else if (display.type === 'connectStripe') {
        notification.message = this._translocoService.translate(`${this._translocoRead}.connect-stripe`, {
          user: userName
        });
      } else if (display.type === 'revokeStripe') {
        notification.message = this._translocoService.translate(`${this._translocoRead}.revoke-stripe`, {
          user: userName
        });
      } else if (display.type === 'connectInstagram') {
        notification.message = this._translocoService.translate(`${this._translocoRead}.connect-instagram`, {
          user: userName
        });
      } else if (display.type === 'revokeInstagram') {
        notification.message = this._translocoService.translate(`${this._translocoRead}.revoke-instagram`, {
          user: userName
        });
      } else if (display.type === 'connectShippo') {
        notification.message = this._translocoService.translate(`${this._translocoRead}.connect-shippo`, {
          user: userName
        });
      } else if (display.type === 'revokeShippo') {
        notification.message = this._translocoService.translate(`${this._translocoRead}.revoke-shippo`, {
          user: userName
        });
      } else if (display.type === 'connectGoogle') {
        notification.message = this._translocoService.translate(`${this._translocoRead}.connect-google`, {
          user: userName
        });
      } else if (display.type === 'revokeGoogle') {
        notification.message = this._translocoService.translate(`${this._translocoRead}.revoke-google`, {
          user: userName
        });
      } else if (display.type === 'payOrder') {
        const order: Order = notification.object;
        const nbItems: number = order.qartOrder.numberOfProducts; // remove the taxes and shipping
        const amount: number = order.qartOrder.total; // convert from cents to unit
        const amountStr: string = this._currencyPipe.transform(amount, order.stripePaymentIntent.currency);
        notification.message = this._translocoService.translate(`${this._translocoRead}.pay-order`, {
          user: userName,
          nbItems,
          amount: amountStr
        });
      } else if (display.type === 'jobFinished') {
        const job: Job = notification.object as Job;
        if (job.status === 'done') {
          notification.message = this._translocoService.translate(`${this._translocoRead}.import-job-done`);
          notification.icon = 'cloud_done';
        } else if (job.status === 'failed') {
          notification.message = this._translocoService.translate(`${this._translocoRead}.import-job-failed`);
          notification.icon = 'cloud_off';
        } else {
          notification.message = this._translocoService.translate(`${this._translocoRead}.import-job-unknown`);
          notification.icon = 'cloud_off';
        }
      }
    } else {
      notification.icon = 'help';
      notification.message = 'Unknown notification';
    }
    return notification;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Tests the websocket connection by emitting a 'test' event with a message.
   */
  test(): void {
    console.log('WEBSOCKET: Testing the websocket');
    this._socket.emit('test', 'blablabla');
  }

}
